import {
  Box,
  Divider,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Input from "components/Input";
import { CLUSTER_STATE } from "constants/common";
import {
  PopupState,
  bindMenu,
  bindTrigger,
} from "material-ui-popup-state/hooks";
import { CustomerEnvironment, OrganizationType } from "utils/saastypes";
import StyledMainButton from "components/buttons/StyledMainButton";
import OutlinedButton from "components/buttons/OutlinedButton";
import { Link } from "react-router-dom";
import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  FilterListRounded,
  RefreshRounded,
  SortRounded,
} from "@mui/icons-material";
import { NEW_CLUSTER } from "utils/routePaths";
import _unique from "lodash/uniq";
import _path from "lodash/fp/path";
import { useMemo } from "react";
import { useAuth } from "utils/AuthProvider";
import { useAuthMachine } from "auth/state/hook";
import { UserInfoModal } from "components/WelcomeUserModal/UserInfoModal";

const inputStyle = {
  "& .MuiInputBase-root": {
    backgroundColor: "#ffffff",
  },
  width: "65%",
  "& label": {
    marginTop: "-6px",
  },
  "& label.MuiFormLabel-filled": {
    marginTop: 0,
  },
  "& label.Mui-focused": {
    marginTop: 0,
  },
  "& input": {
    height: 8,
  },
};

interface ClusterListingHeaderProps {
  filteredEnvironments?: CustomerEnvironment[];
  refetchAllClusters: () => void;
  sortPopupState: PopupState;
  filterPopupState: PopupState;
  clusterData?: CustomerEnvironment[];
  sortingCriteria: "descending" | "ascending";
  setSortingCriteria: (val: "descending" | "ascending") => void;
  handleSearchValueChange: (val: string) => void;
  filters: Record<string, any>;
  setFilters: (val: Record<string, any>) => void;
  toggleFilter: (val: string) => void;
}

const CLUSTER_STATES = Object.values(CLUSTER_STATE);

const MY_CLUSTERS = "myClusters";

export const ClusterListingHeader = ({
  filteredEnvironments,
  clusterData,
  refetchAllClusters,
  sortPopupState,
  filterPopupState,
  setSortingCriteria,
  handleSearchValueChange,
  filters,
  toggleFilter,
  setFilters,
  sortingCriteria,
}: ClusterListingHeaderProps) => {
  const { authService } = useAuth();
  const [
    {
      organizationType,
      isOrkesUser,
      braavosSubscription,
      loggedWithNoOrganization,
    },
    { refetchVerifyUserStatus },
  ] = useAuthMachine(authService);
  const handleFilter = (property: keyof CustomerEnvironment, value: string) => {
    const data = _path(property, filters);
    if (data.indexOf(value) === -1) {
      setFilters({
        ...filters,
        [property]: [...data, value],
      });
    } else {
      setFilters({
        ...filters,
        [property]: data.filter((y: string) => y !== value),
      });
    }
  };

  const shouldShowCreateNewCluster = useMemo(() => {
    const clusterQuota = braavosSubscription?.maxQuota ?? 0;
    const hasQuotasLeftToCreateClusters =
      filteredEnvironments &&
      filteredEnvironments.length > 0 &&
      clusterQuota > filteredEnvironments.length;
    return (
      (loggedWithNoOrganization === false && hasQuotasLeftToCreateClusters) ||
      isOrkesUser
    );
  }, [
    braavosSubscription?.maxQuota,
    filteredEnvironments,
    isOrkesUser,
    loggedWithNoOrganization,
  ]);

  return (
    <Box
      style={{
        borderBottom: "1px solid rgb(200,200,200, 0.5)",
        padding: "0px 25px 25px",
      }}
      id="cluster-listing-main-container"
    >
      <Box>
        <h2 style={{ marginBottom: 10, fontWeight: 500, fontSize: 28 }}>
          Conductor Clusters
        </h2>
      </Box>
      <Box className="conductor-cluster-container">
        <Box style={{ flexBasis: "600px", marginBottom: "15px" }}>
          <Input
            sx={inputStyle}
            id="cluster-listings-search-clusters-input"
            onBlur={(value) => {
              handleSearchValueChange(value);
            }}
            onChange={(value) => {
              handleSearchValueChange(value);
            }}
            clearable
            label="Search Clusters"
            helperText={`Showing ${
              filteredEnvironments ? filteredEnvironments.length : 0
            } of ${clusterData ? clusterData.length : 0} Clusters`}
          />

          {(isOrkesUser ||
            organizationType === OrganizationType.ENTERPRISE) && (
            <OutlinedButton
              {...bindTrigger(filterPopupState)}
              style={{
                fontSize: 15,
                lineHeight: "27px",
                textAlign: "center",
                border: 0,
                width: "9%",
                minWidth: "10px",
              }}
            >
              <FilterListRounded style={{ color: "gray" }} />
            </OutlinedButton>
          )}
          <Menu {...bindMenu(filterPopupState)} sx={{ zIndex: 1999 }}>
            <MenuItem
              onClick={() => {
                toggleFilter(MY_CLUSTERS);
              }}
            >
              {filters[MY_CLUSTERS] ? (
                <CheckBoxRounded />
              ) : (
                <CheckBoxOutlineBlankRounded />
              )}
              Show only my clusters
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (
                  filters.states.length === 0 ||
                  filters.states.indexOf(CLUSTER_STATE.DELETED) === -1
                ) {
                  setFilters({
                    ...filters,
                    states: [...filters.states, CLUSTER_STATE.DELETED],
                  });
                } else {
                  setFilters({
                    ...filters,
                    states: filters.states.filter(
                      (y: string) => y !== CLUSTER_STATE.DELETED
                    ),
                  });
                }
              }}
            >
              {filters.states.indexOf(CLUSTER_STATE.DELETED) !== -1 ? (
                <CheckBoxRounded />
              ) : (
                <CheckBoxOutlineBlankRounded />
              )}
              Show deleted clusters
            </MenuItem>
            <Divider />
            <MenuItem
              disabled={filters.regions.length === 0}
              onClick={() => {
                setFilters({ ...filters, regions: [] });
              }}
            >
              {filters.regions.length === 0 ? (
                <CheckBoxRounded />
              ) : (
                <CheckBoxOutlineBlankRounded />
              )}
              Show clusters from all regions
            </MenuItem>
            {clusterData &&
              _unique(
                clusterData.map((cluster: CustomerEnvironment) => {
                  return cluster.region;
                })
              ).map((x) => {
                return (
                  <MenuItem key={x} onClick={() => handleFilter("regions", x)}>
                    {filters.regions.indexOf(x) !== -1 ? (
                      <CheckBoxRounded />
                    ) : (
                      <CheckBoxOutlineBlankRounded />
                    )}
                    Region:&nbsp;<strong>{x}</strong>
                  </MenuItem>
                );
              })}
            <Divider />
            {CLUSTER_STATES.map((x) => {
              return (
                <MenuItem key={x} onClick={() => handleFilter("states", x)}>
                  {filters.states.indexOf(x) !== -1 ? (
                    <CheckBoxRounded />
                  ) : (
                    <CheckBoxOutlineBlankRounded />
                  )}
                  State:&nbsp;<strong>{x}</strong>
                </MenuItem>
              );
            })}
            <Divider />
            <MenuItem
              disabled={filters[MY_CLUSTERS] || filters.accountIds.length === 0}
              onClick={() => {
                setFilters({ ...filters, accountIds: [] });
              }}
            >
              {filters.accountIds.length === 0 ? (
                <CheckBoxRounded />
              ) : (
                <CheckBoxOutlineBlankRounded />
              )}
              Show clusters from all owners
            </MenuItem>
            {clusterData &&
              _unique(
                clusterData.map((cluster: CustomerEnvironment) => {
                  return cluster.accountId;
                })
              ).map((x) => {
                return (
                  <MenuItem
                    key={String(x)}
                    disabled={filters[MY_CLUSTERS]}
                    onClick={() => handleFilter("accountIds", x)}
                  >
                    {filters.accountIds.indexOf(x) !== -1 ? (
                      <CheckBoxRounded />
                    ) : (
                      <CheckBoxOutlineBlankRounded />
                    )}
                    Account ID:&nbsp;<strong>{x}</strong>
                  </MenuItem>
                );
              })}
          </Menu>

          <OutlinedButton
            {...bindTrigger(sortPopupState)}
            style={{
              fontSize: 15,
              textAlign: "center",
              border: 0,
              width: "9%",
              minWidth: "10px",
            }}
          >
            <SortRounded style={{ color: "gray" }} />
          </OutlinedButton>
          <Menu {...bindMenu(sortPopupState)}>
            <MenuItem
              onClick={() => {
                setSortingCriteria("descending");
              }}
            >
              {sortingCriteria === "descending" ? (
                <CheckBoxRounded />
              ) : (
                <CheckBoxOutlineBlankRounded />
              )}
              Sort by creation date (newest first)
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSortingCriteria("ascending");
              }}
            >
              {sortingCriteria === "ascending" ? (
                <CheckBoxRounded />
              ) : (
                <CheckBoxOutlineBlankRounded />
              )}
              Sort by creation date (oldest first)
            </MenuItem>
          </Menu>

          <Tooltip title={"Refresh cluster listing"}>
            <IconButton
              onClick={refetchAllClusters}
              style={{
                marginTop: 0,
                width: "9%",
                minWidth: "10px",
                color: "gray",
              }}
              disabled={loggedWithNoOrganization}
              size="small"
              sx={{ ml: 1 }}
              aria-controls="basic-menu"
            >
              <RefreshRounded style={{ fontSize: 30 }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ flexBasis: "250px", textAlign: "center" }}>
          {loggedWithNoOrganization && (
            <UserInfoModal refetchVerifyUserStatus={refetchVerifyUserStatus} />
          )}
          {shouldShowCreateNewCluster && (
            <Typography>
              <StyledMainButton
                style={{
                  width: "100%",
                  fontSize: ".9rem",
                  textAlign: "center",
                  borderRadius: 5,
                }}
                // @ts-ignore
                component={Link}
                // @ts-ignore
                to={NEW_CLUSTER}
              >
                Create New Cluster
              </StyledMainButton>
              {/* If ENTERPRISE, they should contact sales to add more clusters */}
              {organizationType === OrganizationType.ENTERPRISE && (
                <Typography fontSize={11} mt={0.5}>
                  Please contact sales to add more clusters
                </Typography>
              )}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
